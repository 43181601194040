@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;800;900&display=swap");

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* option {
  background-color: #D7D7DE;
  color: black;
  font-family: Roboto;
  padding: 1vh 1vw;
  text-align: center;
} */

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

option {
  background-color: #fff;
  color: black;
}

@layer base {
  :root {
    --font-1: "Arial";
    --font-2: "Roboto";
    --background-1: #061b2e;
    --background-2: #647280;
    --background-3: #bfc5ca;
    --background-4: #ffffff;
    --background-5: #1a2d3ea9;
    --background-6: #51606d;
    --background-7: #2a3c4c;
    --background-8: #6e82a0;
    --background-9: #1a2d3e;
    --background-10: #d7d7de;
    --background-11: #be6bcc;
    --background-12: #2a3c4c;
    --background-13: #03dac5;
    --background-14: #ffffffcc;
    --background-15: #3de2d2cc;
    --background-16: #3de2d2;
    --background-17: #f6f7facc;
    --background-18: #f7b7afb3;
    --background-19: #3de2d2b3;
    --background-20: #4dbfdb;
    --background-21: #e9a5e1;
    --background-22: #838d97;
    --background-23: #229cba;
    --background-24: #ef8679;
    --background-25: #edf0f7;
    --background-26: #229cba21;
    --text-1: hsl(208, 13%, 45%);
    --text-2: #bfc5ca;
    --text-3: #ffffff;
    --text-4: #cdd1d5;
    --text-5: #1a3043;
    --text-6: #020202d9;
    --text-7: #ffffffcc;
    --text-8: #ffffff66;
    --text-9: #ffffffd9;
    --text-10: #ffffff80;
    --text-11: #fdf8f880;
    --text-12: #060607cc;
    --text-13: #fdf8f8cc;
    --text-14: #16161c;
    --text-15: #f2f6f7;
    --text-16: #f6f7fa;
    --accent-1: #13c3b2;
    --accent-2: #055b62;
    --accent-3: #0bc4f0;
    --accent-4: #3baca1;
    --accent-5: #ff43a4;
    --accent-6: #1e9ab9;
    --accent-7: #707070;
    --accent-8: #229cba;
    --button-1: #157fcc;
    --button-2: #65caaf;
    --button-3: #f7b7af;
    --button-4: #f18172;
    --button-5: #1e9ab9;
    --border-1: #f6f7fa26;
    --height-1: 700px;
    --height-2: 572px;
    --turquoise-1: #4dbfdb;
  }

  .salon {
    --font-1: "Arial";
    --font-2: "Roboto";
    --button-1: #f4831f;
    --button-2: #f0beb9;
    --button-3: #969a9b;
    --button-5: #f4831f;
    --background-4: #9ba4ab;
    --background-11: #f0beb9;
    --background-23: #f4831f;
  }
}

main {
  flex-grow: 1;
}

.fc-view {
  background-color: #ffffff;
}

.tippy-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d7d7de 0% 0% no-repeat padding-box;
  border: 1px solid #f6f7fa;
  opacity: 1;
  border-radius: 0%;
  width: 135px;
  height: 77px;
  text-align: center;
  font: normal normal normal 10px/15px Roboto;
  letter-spacing: 0px;
  color: #16161c;
}

.dot-blue {
  height: 12px;
  width: 12px;
  background-color: #359eeb;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}

.dot-white {
  height: 12px;
  width: 12px;
  background-color: #f6f7fa;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}

.dot-beach {
  height: 12px;
  width: 12px;
  background-color: #3de2d2;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}

.blue-border:hover {
  border-color: #0ba0c5;
}

.input-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.upload_tag {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.placeholder-font-lg::placeholder {
  font-size: 1.125rem;
  font-weight: 600;
}
