.topContainer {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #13c3b2;
    text-align: center;
    padding: 20px 0;
    position: relative;
}

.title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    color: white;
    font-size: 25px;
    font-weight: normal;
}

.form {
    width: 77%;
    text-align: center;
    margin: 30px auto;
    font-weight: normal;
}

.formButton {
    background-color: #13c3b2;
    color: white;
    width: 180px;
    border-radius: 5px;
    padding: 3px;
}

.agreement {
    width: 100%;
    height: 450px;
    /* background-color: #647280; */
    margin: 25px 0;
    overflow: auto;
}

.sigPad {
    background-color: #647280;
    width: 60%;
    height: 20vh;
    margin: 25px auto;
}

:root {
    --my-counter: 0;
    --my-counter1: 0;
}

.orderListAlphaWithBrackets{
    counter-reset: myCounter var(--my-counter);
}

.orderListAlphaWithBracketsSecond{
    counter-reset: myCounter 4;
}

.orderListAlphaWithBrackets > li {
    counter-increment: myCounter;
}

.orderListAlphaWithBrackets > li::before{
    content: "(" counter(myCounter, lower-alpha) ") ";
    position: absolute;
    left: -2em;
    font-weight: bold;
}

.orderListAlpha{
    counter-reset: myCounter1 var(--my-counter1);
}

.orderListAlpha > li {
    counter-increment: myCounter1;
}

.orderListAlpha > li::before{
    content:counter(myCounter1, lower-alpha) ". ";
    position: absolute;
    left: -2em;
}

.indent{
    text-indent: 2rem;
}

input::placeholder{
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.9rem;
}