.topContainer {
    width: 90%;
    margin: auto;
    padding: 40px 0;
    border-bottom: 1px solid rgba(145, 145, 145, 0.402);
}

.logo {
    width: 80px;
    margin: auto;
}

.form {
    margin: 65px auto;
    color: white;
    font-weight: normal;
    text-align: center;
    flex-grow: 1;
}

.optionContainer {
    margin: 50px auto;
    padding-left: 30%;
    font-size: 16px;
    /* text-transform: uppercase; */
    transform: translate(-17%, 0);
}
.option {
    display: flex;
    margin-bottom: 20px;
}
.optionContent {
    text-align: left;
    margin-left: 25px;
}

.message {
    font-size: 12px;
}

.formButton {
    background-color: #13c3b2;
    color: white;
    width: 180px;
    border-radius: 5px;
    padding: 3px;
    margin-top: 40px;
}