
.microphoneContainer {
  display: flex;
	flex: 0 1 100%;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  
}







#circlein {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: var(--background-26);
	position: relative;
}

#circlein>.animation-pulse{
	content: "";
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: var(--background-26);
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(0.8);
	transition: scale ease-in 0.2s;
}